import React from "react";
import { graphql } from "gatsby";

import {
	Container,
	Box,
	Typography,
	Avatar,
	Link,
	Grid
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { Mdx } from "@runly/gatsby-theme";

import { ReadingThemeProvider } from "../theme";
import Layout from "../layout";
import Seo from "../seo";
import Social from "../social";

const AboutPage = ({
	location,
	data: {
		file: {
			childMdx: {
				frontmatter: { title, slogan },
				body
			}
		},
		team: { nodes: team }
	}
}) => (
	<Layout location={location} maxWidth="md">
		<Seo title={title} description={slogan} />

		<ReadingThemeProvider>
			<Container maxWidth="md">
				<Box pb={5} component="header">
					<Typography variant="h2" component="h1">
						{title}
					</Typography>
					<Typography variant="h4" component="h2">
						{slogan}
					</Typography>
				</Box>

				<article>
					<Mdx>{body}</Mdx>
				</article>

				<section>
					<Typography variant="h4" component="h2">
						Meet the Team
					</Typography>

					<TeamList team={team} />
				</section>
			</Container>
		</ReadingThemeProvider>
	</Layout>
);

const TeamList = ({ team }) => {
	const classes = useTeamListStyles();

	return (
		<Grid container className={classes.list} spacing={4}>
			{team.map(member => (
				<Grid key={member.id} item xs={12} sm={6} className={classes.item}>
					<TeamMember {...member} />
				</Grid>
			))}
		</Grid>
	);
};

const useTeamListStyles = makeStyles(theme => ({
	list: {
		margin: theme.spacing(2, 0)
	},
	item: {
		textAlign: "center"
	}
}));

const TeamMember = ({ name, site, avatar, ...social }) => {
	const classes = useTeamMemberStyles();

	return (
		<>
			<Avatar
				src={avatar.fixed.src}
				alt={name}
				title={name}
				className={classes.pic}
			/>
			<Typography variant="h6" component="h3">
				{site ? (
					<Link href={site} color="inherit">
						{name}
					</Link>
				) : (
					<>{name}</>
				)}
			</Typography>
			<Social {...social} />
		</>
	);
};

const useTeamMemberStyles = makeStyles({
	pic: {
		display: "inline-block",
		width: 200,
		height: 200
	}
});

export default AboutPage;

export const aboutQuery = graphql`
	query AboutQuery {
		file(name: { eq: "about" }, sourceInstanceName: { eq: "snippet" }) {
			childMdx {
				frontmatter {
					title
					slogan
				}
				body
			}
		}
		team: allBlogAuthor(sort: { fields: name }) {
			nodes {
				id
				name
				site
				twitter
				github
				avatar {
					fixed(width: 300) {
						...GatsbyImageSharpFixed
					}
				}
			}
		}
	}
`;
