import React from "react";

import { IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import {
	Twitter as TwitterIcon,
	GitHub as GitHubIcon
} from "@material-ui/icons";

const Social = ({ github, twitter }) => {
	const classes = useSocialStyles();

	if (!github && !twitter) {
		return null;
	}

	return (
		<ul className={classes.container}>
			{github ? (
				<IconButton
					color="inherit"
					aria-label="GitHub"
					title="GitHub"
					href={`https://github.com/${github}`}
				>
					<GitHubIcon fontSize="small" />
				</IconButton>
			) : null}
			{twitter ? (
				<IconButton
					color="inherit"
					aria-label="Twitter"
					title="Twitter"
					href={`https://twitter.com/${twitter}`}
				>
					<TwitterIcon fontSize="small" />
				</IconButton>
			) : null}
		</ul>
	);
};

const useSocialStyles = makeStyles({
	container: {
		listStyle: "none",
		margin: 0,
		padding: 0
	}
});

export default Social;
